import React from 'react';
import { Box, Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import { navigate } from 'gatsby'
import SidebarDSA from '../../components/SidebarDSA';

export default function PersonaDSA() {
  const onClickButton_DSAMyLearning = () => {
    navigate ('/data-security-analyst')
  }
  const onClickButton_DSAExplore = () => {
    navigate ('/data-security-analyst/dsa-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'85vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarDSA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/11eP6D2VITUZktPcdA7rQ6/8486a4b6086140bbb7a2335edd036af8/dis-pe-dsa-info-governance.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} />
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '20%'}} kind='text' onClick={onClickButton_DSAMyLearning} >&nbsp; &nbsp;<div className='beacon'></div></Button>
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '30%'}} kind='text' onClick={onClickButton_DSAExplore} >&nbsp; &nbsp;<div className='beacon'></div></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}